export function useDashboard() {
    const currentDashboardSection = useState<string | null>('current-dashboard-section-id', () => null)

    const log = useLog()
    const router = useRouter()

    const setDashboardSection = (id: string, route?: string) => {
        log.debug('Setting dashboard section', { id, route })
        currentDashboardSection.value = id

        if (route) {
            navigateTo(route)
            return
        }

        const sectionRoute = router.getRoutes().find(r => r.name === id)
        if (!sectionRoute) {
            log.warn('Route not found for id', { id })
            return
        }

        const { name, path } = sectionRoute
        log.debug('Navigating to route', { name, path })
        navigateTo(path)
    }

    return {
        currentDashboardSection,
        setDashboardSection,
    }
}
